
import {Component, Prop, Vue} from 'vue-property-decorator';
import Pass from '@/components/models/Pass';
import PassHelper from '@/components/PassHelper';
import TransportV2 from '@/components/models/TransportV2';
import {createLogger} from 'vuex';

@Component({})
export default class TransportModeBlockPass extends Vue {

  @Prop()
  transport!: TransportV2;

  @Prop()
  timeOfDay!: 'DAY' | 'NIGHT';

  passBA: Pass | null = null;
  passBB: Pass | null = null;

  showPass = true;

  statusId = 0;
  statusLabel = '';
  statusColor = 'green';
  forecastDate: string | null = null;

  label = '';
  icon = '';
  iconColor = '';


  pass: Pass | null = null;
  allowedZone = '';
  number = '';
  color = '';
  title = '';
  activeDate = '';
  startDate = '';
  endDate = '';

  created() {

    this.showPass = true;

    if (this.timeOfDay === 'DAY') {

      this.statusId = this.transport.passDayStatusId;
      this.forecastDate = this.transport.passDayForecastDate;

      this.label = 'Дневной пропуск';
      this.icon = 'weather-sunny';
      this.iconColor = '#F9A825';

      this.passBA = new Pass();
      this.passBA.number = this.transport.passDayBANumber;
      this.passBA.startDate = this.transport.passDayBAStartDate;
      this.passBA.endDate = this.transport.passDayBAEndDate;
      this.passBA.cancelDate = this.transport.passDayBACancelDate;
      this.passBA.allowedZone = this.transport.passDayBAAllowedZone;

      this.passBB = new Pass();
      this.passBB.number = this.transport.passDayBBNumber;
      this.passBB.startDate = this.transport.passDayBBStartDate;
      this.passBB.endDate = this.transport.passDayBBEndDate;
      this.passBB.allowedZone = this.transport.passDayBBAllowedZone;


    } else {
      this.statusId = this.transport.passNightStatusId;
      this.forecastDate = this.transport.passNighForecastDate;

      this.label = 'Ночной пропуск';
      this.icon = 'weather-night';
      this.iconColor = '#0D47A1';

      this.passBA = new Pass();
      this.passBA.number = this.transport.passNightBANumber;
      this.passBA.startDate = this.transport.passNightBAStartDate;
      this.passBA.endDate = this.transport.passNightBAEndDate;
      this.passBA.cancelDate = this.transport.passNightBACancelDate;
      this.passBA.allowedZone = this.transport.passNightBAAllowedZone;

      this.passBB = new Pass();
      this.passBB.number = this.transport.passNightBBNumber;
      this.passBB.startDate = this.transport.passNightBBStartDate;
      this.passBB.endDate = this.transport.passNightBBEndDate;
      this.passBB.allowedZone = this.transport.passNightBBAllowedZone;

    }

    this.statusLabel = PassHelper.getStatusLabel(this.statusId, this.forecastDate);
    this.statusColor = PassHelper.getStatusColor(this.statusId);


    const BADay = PassHelper.calcLeftDays(this.passBA.endDate);
    const BBDay = PassHelper.calcLeftDays(this.passBB.endDate);


    this.pass = new Pass();

    if ((!BADay || this.passBA.cancelDate) && BBDay) {
      this.pass = this.passBB;
    } else if (this.passBA) {
      this.pass = this.passBA;
    }

    const current = Number(this.$moment(this.$moment().format('YYYY-MM-DD')).format('x'));
    //
    // let endBB = 0;
    // let endBA = 0;
    //
    // if (this.passBB) {
    //   endBB = Number(this.$moment(this.passBB.endDate).format('x'));
    // }
    //
    // if (this.passBA) {
    //   endBA = Number(this.$moment(this.passBA.endDate).format('x'));
    //
    //   if (this.passBA.cancelDate && this.passBB && endBB > current) {
    //    endBA = 0;
    //   }
    //
    // }
    //
    // if (
    //     endBA < endBB
    // ) {
    //   this.pass = this.passBB;
    // } else {
    //   this.pass = this.passBA;
    // }


    if (this.pass) {

      this.number = `${this.pass.series}${this.pass.number}`;
      this.allowedZone = this.pass.allowedZone;

      this.startDate = this.$moment(this.pass.startDate).format('DD.MM.YYYY');
      this.endDate = this.$moment(this.pass.endDate).format('DD.MM.YYYY');


      if (Number(this.$moment(this.pass.endDate).format('x')) >= current) {

        const diffDays =
            this.$moment(
                this.pass.endDate + ' 23:59:59'
            ).utc(true).diff(
                this.$moment(
                    this.$moment().format('YYYY-MM-DD 00:00:00')
                ).utc(true)
                , 'days');

        this.title = `Действует ${this.pass.series}, `;

        if (diffDays !== 0) {
          this.title += `осталось ${diffDays} дн.`;
        } else {
          this.title += `заканчивается сегодня.`;
        }

        if (diffDays > 30) {
          this.color = 'green';
        } else {
          this.color = 'yellow darken-4';
        }
      } else {
        if (this.pass.series !== 'ББ') {
          this.title = `Закончился  ` + this.$moment(this.pass.endDate).format('DD.MM.YYYY');
          this.color = 'red';
        } else {
          this.showPass = false;
        }
      }

      console.log(this.pass.cancelDate);

      if (this.pass.cancelDate) {
        this.title = `Аннулирован ` + this.$moment(this.pass.cancelDate).format('DD.MM.YYYY');
        this.color = 'red darken-4';
      }

    }

  }
}
