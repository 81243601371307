
import {Component, Vue} from 'vue-property-decorator';
import VMaskPhone from '@/UI/VMaskPhone.vue';
import AppStore from '@/components/AppStore';
import UserManager from '@/models/UserManager';
import TelegramIcon from '@/components/icons/TelegramIcon.vue';

@Component({
  components: {TelegramIcon, VMaskPhone}
})
export default class ManagerBlock extends Vue {


  manager = new UserManager();
  assistant = new UserManager();

  loading = false;

  created() {
    this.manager = AppStore.manager;
    this.assistant = AppStore.assistant;

  }

}

