
import {Component, Prop, Vue} from 'vue-property-decorator';
import PassHelper from '@/components/PassHelper';

@Component({})
export default class ModeTableStatusIcon extends Vue {

  @Prop()
  statusId!: number;

  @Prop()
  forecastDate!: string | null;

  passHelper: any = PassHelper;


}
