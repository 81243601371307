
import {Component, Prop, Vue} from 'vue-property-decorator';
import Pass from '@/components/models/Pass';
import Transport from '@/components/models/Transport';
import PassHelper from '@/components/PassHelper';

@Component({})
export default class PassBlock extends Vue {

  @Prop()
  transport!: Transport;

  @Prop()
  timeOfDay!: number;

  passBA: Pass | null = null;
  passBB: Pass | null = null;

  showPass = true;

  statusId = 0;
  statusLabel = '';
  statusColor = 'green';
  forecastDate: string | null = null;

  label = '';
  icon = '';
  iconColor = '';


  pass: Pass | null = null;
  allowedZone = '';
  number = '';
  color = '';
  title = '';
  activeDate = '';
  startDate = '';
  endDate = '';

  created() {

    this.showPass = true;

    if (this.timeOfDay === 1) {
      this.label = 'Дневной пропуск';
      this.icon = 'weather-sunny';
      this.iconColor = '#F9A825';
      this.passBA = this.transport.dayBAPass;
      this.passBB = this.transport.dayBBPass;
      this.statusId = this.transport.passStatusId;
      this.forecastDate = this.transport.passForecastDate;
    } else {
      this.label = 'Ночной пропуск';
      this.icon = 'weather-night';
      this.iconColor = '#0D47A1';
      this.passBA = this.transport.nightBAPass;
      this.passBB = this.transport.nightBBPass;
      this.statusId = this.transport.passNightStatusId;
      this.forecastDate = this.transport.passNightForecastDate;
    }


    this.statusLabel = PassHelper.getStatusLabel(this.statusId, this.forecastDate );
    this.statusColor = PassHelper.getStatusColor(this.statusId);

    const current = Number(this.$moment(this.$moment().format('YYYY-MM-DD')).format('x'));

    let endBB = 0;
    let endBA = 0;

    if (this.passBB) {
      endBB = Number(this.$moment(this.passBB.endDate).format('x'));
    }

    if (this.passBA) {
      endBA = Number(this.$moment(this.passBA.endDate).format('x'));

      if (this.passBA.cancelDate && this.passBB && endBB > current) {
        endBA = 0;
      }

    }


    if (
        endBA < endBB
    ) {
      this.pass = this.passBB;
    } else {
      this.pass = this.passBA;

    }


    if (this.pass) {

      this.number = `${this.pass.series}${this.pass.number}`;
      this.allowedZone = this.pass.allowedZone;

      this.startDate = this.$moment(this.pass.startDate).format('DD.MM.YYYY');
      this.endDate = this.$moment(this.pass.endDate).format('DD.MM.YYYY');


      if (Number(this.$moment(this.pass.endDate).format('x')) >= current) {

        const diffDays =
            this.$moment(
                this.pass.endDate + ' 23:59:59'
            ).utc(true).diff(
                this.$moment(
                    this.$moment().format('YYYY-MM-DD 00:00:00')
                ).utc(true)
                , 'days');

        this.title = `Действует ${this.pass.series}, `;

        if (diffDays !== 0) {
          this.title += `осталось ${diffDays} дн.`;
        } else {
          this.title += `заканчивается сегодня.`;
        }

        if (diffDays > 30) {
          this.color = 'green';
        } else {
          this.color = 'yellow darken-4';
        }
      } else {

        if (this.pass.series !== 'ББ') {
          this.title = `Закончился  ` + this.$moment(this.pass.endDate).format('DD.MM.YYYY');
          this.color = 'red';
        } else {
          this.showPass = false;
        }
      }

      if (this.pass.cancelDate) {
        this.title = `Аннулирован ` + this.$moment(this.pass.cancelDate).format('DD.MM.YYYY');
        this.color = 'red darken-4';
      }

    }

  }
}
