import Vue from 'vue';

class PassHelper extends Vue {

    getStatusIcon(statusId: number): string {
        switch (statusId) {
            case 1:
            case 2:
                return 'mdi-briefcase-arrow-left-right-outline';
            case 3:
                return 'mdi-send-circle-outline';
            case 4:
                return 'mdi-send-circle-outline';
            case 5:
                return 'mdi-checkbox-outline';
            case 6:
                return 'mdi-checkbox-outline';
        }
        return '';
    }

    getStatusColor(statusId: number): string {
        switch (statusId) {
            case 1:
            case 2:
                return 'grey';
            case 3:
                return 'blue darken-1';
            case 4:
                return 'blue darken-1';
            case 5:
                return 'green lighten-1';
            case 6:
                return 'green darken-2';
        }

        return '';
    }

    getStatusLabel(statusId: number, forecastDate: string | null): string {
        switch (statusId) {
            case 1:
            case 2:
                return 'Заявка в обработке';
            case 3:
                return 'Подан разовый';
            case 4:
                return 'Подан годовой, прогноз выдачи ' + this.$moment(forecastDate).format('DD.MM.YYYY');
            case 5:
                return 'Получен разовый пропуск';
            case 6:
                return 'Получен годовой пропуск';
        }
        return '';
    }

    calcLeftDays(end: string) {
        const current = this.$moment().startOf('day');
        const r = this.$moment(end).startOf('day').diff(current, 'days');
        if (isNaN(r)) {
            return null;
        }
        if (r <= 0) {
            return null;
        }
        return r;
    }

}


export default new PassHelper();
